button {
	appearance: none;
	font-family: inherit;
	font-size: inherit;
	border: none;
	background: none;
	cursor: pointer;
}

.accordion-wrapper {
	border-top: 1px solid #606060;
	width: 100%;
	margin-inline: auto;
	display: none;
	flex-direction: column;
	@include query-to(760) {
		display: flex;
	}
}

.accordion {
	width: 100%;
	&__panel {
		border-bottom: 1px solid #606060;
		overflow: hidden;
		//transition: height 0.5s ease-in-out;
		&:last-child {
			border-bottom: 0;
		}
	}

	&__heading {
		cursor: pointer;
		display: flex;
		align-items: center;
		position: relative;
		&.is-active {
			.accordion__btn {
				.inner {
					.icon {
						opacity: 1;
					}
				}
			}
		}
	}

	&__btn {
		color: $white;
		display: flex;
		align-items: center;
		//padding: rem(15) 0;
		padding: rem(13) 0 rem(11);
		width: 100%;
		font-size: rem(18);
		text-align: left;
		font-weight: 700;
		text-transform: uppercase;
		.inner {
			position: relative;
			.icon {
				opacity: 0;
				transition: opacity .3s ease;
				position: absolute;
				top: 50%;
				right: -45px;
				transform: translate(0,-50%);
				display: flex;
	    		align-items: center;
				justify-content: flex-start;
				width: 35px;
				height: 35px;
				margin-bottom: rem(15);
				img {
			    	width: 100%;
					height: 100%;
				}
			}
		}

		&::after {
			position: absolute;
			right: 0;
			content: "+";
			font-weight: 400;
			width: 30px;
			aspect-ratio: 1;
			margin-left: auto;
			color: $white;
			font-size: rem(35);
			transition: transform 0.2s ease-in-out;
			.is-active & {
				font-size: 40px;
				transform: rotate(45deg);
			}
		}
	}

	&__content {
		max-height: 0;
		margin-top: -1px;
		//transition: height 0.35s ease-in-out;
		overflow: hidden;
		font-size: rem(14);
		//transition: max-height 0.2s ease-out;

		.is-active & {
			display: block;
		}
	}

	&__inner {
		padding-bottom: rem(25);
		p {
			font-weight: 300;
			line-height: 1.25;
		 	padding-bottom: 2rem;
		 	&:last-child {
		 		padding-bottom: 0
		 	}
		}
	}
}
