// hero

.hero {
  padding-top: rem(112.5);
  position: relative;
  @include query-to(991) {
    padding-top: rem(79);
  }

  // Design Slider
  .slider, .slide {
    height: 400px;
    @media (min-width: 992px) {
      height: 540px; //587.5px;
    }
  }

  
    .slider-navigation {
      position: absolute;
      top: 112.5px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 112.5px);
      .container {
        position: relative;
        width: 100%;
        height: 100%;
        .left {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translate(0,-50%);
          z-index: 1;
          cursor: pointer;
          transition: opacity .3s ease;
          opacity: .6;
          &:hover {
            opacity: 1;
          }
        }
        .right {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(0,-50%);
          z-index: 1;
          cursor: pointer;
          transition: opacity .3s ease;
          opacity: .6;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    
  

  .slide {
    position: relative;
    .slide__img {
      @media (min-width: 992px) {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      width: 100%;
      height: auto;
      overflow: hidden;
      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        opacity: 1 !important;
        animation-duration: 3s;
        transition: all 1s ease;
        @include query-to(991) {
          height: 400px;
          object-fit: cover;
        }
      }
    }
    .slide__content {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &--headings {
        color: #FFF;
        p {
          font-weight: 800;
          font-size: rem(70);
          line-height: 1.2;
          text-transform: uppercase;
          @include query-to(991) {
            font-size: rem(50);
          }
          @include query-to(700) {
            font-size: rem(40);
          }
          @include query-to(500) {
            font-size: rem(30);
          }
          @include query-to(400) {
            font-size: rem(28);
          }
          &.black {
            color: $black;
          }
        }
        .animated {
          transition: all .5s ease;
        }
      }
    }
  }

  .slider [data-animation-in] {
      opacity: 0;
      animation-duration: 1.5s;
      transition: opacity 0.5s ease 0.3s;
  }

  .slick-dotted .slick-slider {
      margin-bottom: 30px;
  }

  .slick-dots {
      position: absolute;
      bottom: 25px;
      list-style: none;
      display: block;
      text-align: center;
      padding: 0;
      margin: 0;
      width: 100%;
      z-index: 1;
      @include query-to(600) {
        bottom: 5px;
      }
      li {
          position: relative;
          display: inline-block;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;
          button {
              border: 0;
              display: block;
              outline: none;
              line-height: 0px;
              font-size: 0px;
              color: transparent;
              padding: 5px;
              cursor: pointer;
              transition: all .3s ease;
              &:hover, &:focus {
                  outline: none;
              }
          }
      }
  }

  .simple-dots {
    .slick-dots {
      @include query-to(600) {
        bottom: 15px;
      }
      li {
        width: 20px;
        height: 20px;
        button {
          border-radius: 50%;
          background-color: $white;
          opacity: 0.25;
          width: 20px;
          height: 20px;
          &:hover, &:focus {
            opacity: 1;
          }
        }
        &.slick-active button {
          color: $white;
          opacity: 0.75;
        }
      }
    }
  }

  .stick-dots {
    .slick-dots {
      li {
        height: 3px;
        width: 50px;
        @include query-to(600) {
          width: 25px;
        }
        button {
          position: relative;
          background-color: $white;
          opacity: 0.25;
          width: 50px;
          height: 3px;
          padding: 0;
          @include query-to(600) {
            width: 25px;
          }
          &:hover,
          &:focus {
            opacity: 1;
          }
        }
        &.slick-active button {
          color: $white;
          opacity: 0.75;
          &:hover, &:focus {
            opacity: 1;
          }
        }
      }
    }
  }

  /* /////////// IMAGE ZOOM /////////// */
  @keyframes zoomInImage {
    from {
      transform: scale3d(1, 1, 1);
    }
    to {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }

  .zoomInImage {
    animation-name: zoomInImage;
  }

  @keyframes zoomOutImage {
    from {
      transform: scale3d(1.1, 1.1, 1.1);
    }
    to {
      transform: scale3d(1, 1, 1);
    }
  }

  .zoomOutImage {
    animation-name: zoomOutImage;
  }
}

// ABOUT
.about {
  padding-top: rem(70);
  @include query-to(760) {
    padding-top: rem(50);
  }
  .container {
    display: flex;
    @include query-to(760) {
      flex-direction: column;
    }
    .left {
      max-width: 210px;
      width: 100%;
      flex: 1 0 auto;
      text-align: right;
      padding-right: rem(15);
      border-right: solid 1px $white;
      @include query-to(760) {
        max-width: 100%;
        flex: auto;
        padding-right: 0;
        border-right: none;
        text-align: left;
      }
      h2 {
        text-transform: uppercase;
        padding-bottom: rem(20);
        @include query-to(760) {
          font-size: rem(18);
        }
      }
      p {
        font-weight: 600;
        line-height: 1.25;
        padding-bottom: rem(25);
        font-size: rem(14);
      }
      & > :last-child {
        padding-bottom: 0;
        @include query-to(760) {
          display: none;
        }
      }
    }
    .right {
      width: 100%;
      font-weight: 300;
      // padding: rem(24) 0 rem(24) rem(15);
      padding: 0 0 0 rem(15);
      @include query-to(760) {
        padding: 0;
      }
      p {
        line-height: 1.25;
        padding-bottom: rem(20);
        @include query-to(760) {
          font-size: rem(14);
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

// SERVICES
.services {
  margin-top: rem(70);
  padding: 0 0 rem(70);
  @include query-to(760) {
    margin-top: rem(50);
    padding:0 0 rem(50);
  }

  .container {

    .services-grid {
      border-top: 1px solid #606060;
      padding-top: rem(70);
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @include query-to(1240) {
        grid-template-columns: 1fr 1fr;
      }
      @include query-to(760) {
        display: none;
      }
      .services-grid__item {
        transition: all .5s ease;
        cursor: pointer;
        position: relative;
        margin-left: 0;
        margin-right: 0;
        padding: rem(10);
        border: solid 1px #404040;
        border-top: 0;
        border-left: 0;
        &:nth-child(4),
        &:nth-child(8) {
          border-right: 0;
          @include query-to(1240) {
            border-right: solid 1px #404040;
          }
        }
        &:nth-child(even) {
          @include query-to(1240) {
            border-right: 0;
          }
        }
        &:nth-last-of-type(-n+4) {
          border-bottom: 0;
          @include query-to(1240) {
            border-bottom: solid 1px #404040;
          }
        }
        &:nth-last-of-type(-n+2) {
          @include query-to(1240) {
            border-bottom: 0;
          }
        }

        .inner {
          opacity: .6;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 279px;
          border: solid 1px transparent;
          transition: .3s;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 108px;
            img {}
          }
          h3 {
            font-weight: 800;
            padding-top: rem(18);
            text-transform: uppercase;
            text-align: center;
          }
        }
        .overlay {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: solid 1px transparent;
          transition: border-color .3s ease;
          .content {
            max-width: 1020px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            padding: rem(50) 0;
            .left {
              max-width: 210px;
              width: 100%;
              flex: 1 0 auto;
              text-align: right;
              padding-right: rem(15);
              border-right: solid 1px $white;
              span {
                font-weight: 800;
                line-height: 1.25;
              }
              p {
                font-weight: 800;
                text-transform: uppercase;
                line-height: 1.25;
              }
            }
            .right {
              width: 100%;
              padding: 0 0 0 rem(15);
              font-weight: 300;
              p {
                line-height: 1.25;
                padding-bottom: rem(20);
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
          .close {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
            width: 27px;
            height: 27px;
            background: url("../img/icons/modal-close-icon.svg") center no-repeat;
            background-size: contain;
          }
        }
        &:hover {
          .inner {
            opacity: 1;
          }

        }
        &.active {
          cursor: default;
          z-index: 2;
          background: $black !important;
          transition: all .4s ease;
          .inner {
            animation: overlay_hidden .5s .3s linear both;
            opacity: 0;
            visibility: hidden;
            @include query-to(1240) {
              height: 400px;
            }
          }
          .overlay {
            transition: border-color .3s ease;
            border-color: $white;
            opacity: 1;
            visibility: visible;
            animation: overlay_active .5s .3s linear both;
          }
        }
        &:nth-child(4n+1).active {
          margin-right: -300%;
          @include query-to(1240) {
            margin-right: -100%;
          }
        }
        &:nth-child(4n+2).active {
          margin-left: -100%;
          margin-right: -200%;
          @include query-to(1240) {
            margin-right: 0;
          }
        }
        &:nth-child(4n+3).active {
          margin-left: -200%;
          margin-right: -100%;
          @include query-to(1240) {
            margin-left: 0;
          }
        }
        &:nth-child(4n+4).active {
          margin-left: -300%;
          @include query-to(1240) {
            margin-left: -100%;
          }
        }

      }
      @keyframes overlay_active {
          from {
              opacity: 0;
          }
      }
      @keyframes overlay_hidden {
          from {
              opacity: 1;
          }
      }
    }

    /* Style the tab */
    .tabs {
      overflow: hidden;
      padding-top: rem(55);
      padding-bottom: rem(60);
      border-top: 1px solid #606060;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      /* Style the buttons inside the tab */
      .tab__link {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: rem(10);
        transition: 0.3s;
        font-size: rem(16);
        border: solid 1px #404040;
        border-top: 0;
        border-left: 0;
        &:nth-child(4),
        &:nth-child(8) {
          border-right: 0;
        }
        &:nth-last-of-type(-n+4) {
          border-bottom: 0;
        }
        color: $white;
        .inner {
          opacity: .6;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 279px;
          border: solid 1px transparent;
          transition: .3s;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 108px;
            img {}
          }
          h3 {
            font-weight: 800;
            padding-top: rem(18);
            text-transform: uppercase;
          }
        }
        &:hover {
          .inner {
            opacity: 1;
          }
        }
        &.active {
          .inner {
            opacity: 1;
            border-color: $white;
          }
        }
      }

    }

    /* Style the tab content */
    .tab__content {
      display: none;
      padding: 6px 12px;
      -webkit-animation: fadeEffect 1s;
      animation: fadeEffect 1s;
      .inner {
        max-width: 1020px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        .left {
          max-width: 210px;
          width: 100%;
          flex: 1 0 auto;
          text-align: right;
          padding-right: rem(15);
          border-right: solid 1px $white;
          span {
            font-weight: 800;
            line-height: 1.25;
          }
          p {
            font-weight: 800;
            text-transform: uppercase;
            line-height: 1.25;
          }
        }
        .right {
          width: 100%;
          padding: rem(24) 0 rem(24) rem(15);
          p {
            line-height: 1.25;
            padding-bottom: rem(20);
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }

    /* Fade in tabs */
    @-webkit-keyframes fadeEffect {
      from {opacity: 0;}
      to {opacity: 1;}
    }

    @keyframes fadeEffect {
      from {opacity: 0;}
      to {opacity: 1;}
    }

  }
}

// PROJECTS
.projects {
  padding-bottom: rem(70);
  @include query-to(760) {
    padding-bottom: rem(50);
  }
  .container {
    @include query-to(1440) {
      padding: 0;
    }
    .tabs {
      border-top: 1px solid #606060;
      .wrapper {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        padding-top: rem(55);
        padding-bottom: rem(60);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @include query-to(760) {
          max-width: 500px;
          padding-bottom: rem(30);
        }
        @include query-to(500) {
          max-width: 340px;
        }
      }

      /* Style the buttons inside the tab */
      .tab__link_2 {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: rem(10);
        transition: 0.3s;
        font-size: rem(16);
        border: solid 1px #404040;
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
        @include query-to(500) {
          //width: 100px;
          padding: rem(6);
        }
        &:last-child {
          border-right: 0;
        }
        color: $white;
        .inner {
          opacity: .6;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 279px;
          border: solid 1px transparent;
          transition: .3s;
          @include query-to(900) {
            height: 240px;
          }
          @include query-to(800) {
            height: 220px;
          }
          @include query-to(760) {
            height: auto;
            padding: rem(32) rem(20);
          }
          @include query-to(500) {
            padding: rem(10);
            height: 100px;
          }
          
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 108px;
            @include query-to(760) {
              height: 40px;
            }
            @include query-to(500) {
              height: 30px;
            }
            img {
              @include query-to(760) {
                width: 100%;
                height: 100%;
              }
            }
          }
          p {
            font-weight: 800;
            padding-top: rem(18);
            text-transform: uppercase;
             @include query-to(760) {
              font-size: rem(14);
             }
             @include query-to(500) {
              font-size: rem(12);
              padding-top: rem(15);
             }
          }
        }
        &:hover {
          .inner {
            opacity: 1;
          }
        }
        &.active {
          .inner {
            opacity: 1;
            border-color: $white;
          }
        }
      }

    }

    /* Style the tab content */
    .tab__content_2 {
      display: none;
      -webkit-animation: fadeEffect 1s;
      animation: fadeEffect 1s;
      .inner-projects {
        position: relative;
        margin-bottom: rem(70);
        @include query-to(760) {
          margin-bottom: rem(50);
        }
        .projects-slider {
          .slick-track {
            .slick-slide > div:first-child,
            .slick-slide > div:last-child {
              display: flex;
            }
          }
          .project {
            position: relative;
            cursor: pointer;
            img {
              max-width: 100%;
              width: 100% !important;
            }
            .overlay {
              opacity: 0;
              transition: opacity .3s ease;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              padding: rem(20);
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(0, 0, 0, .75);
              h3 {
                font-weight: 800;
                text-transform: uppercase;
              }
              @include query-to(760) {
                display: none;
              }
            }
            &:hover {
              .overlay {
                opacity: 1;
              }
            }
          }
          .slick-dotted .slick-slider {
            margin-bottom: 30px;
          }

          .slick-dots {
            position: absolute;
            bottom: 25px;
            list-style: none;
            display: block;
            text-align: center;
            padding: 0;
            margin: 0;
            width: 100%;
            z-index: 1;
            @include query-to(600) {
              bottom: 15px;
            }

            li {
              position: relative;
              display: inline-block;
              margin: 0 5px;
              padding: 0;
              cursor: pointer;
              height: 3px;
              width: 50px;
              @include query-to(600) {
                width: 25px;
              }

              button {
                position: relative;
                background-color: #fff;
                opacity: .25;
                width: 50px;
                height: 3px;
                padding: 0;
                border: 0;
                display: block;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                cursor: pointer;
                transition: all .3s ease;
                @include query-to(600) {
                  width: 25px;
                }

                &:hover, &:focus {
                  outline: none;
                }
              }

              &.slick-active button {
                color: #fff;
                opacity: .75;
              }
            }
          }
        }
        .projects-slider-navigation {
          @include query-to(760) {
            display: none;
          }
          .left {
            position: absolute;
            top: 50%;
            left: -70px;
            transform: translate(0,-50%);
            z-index: 1;
            cursor: pointer;
            transition: opacity .3s ease;
            opacity: .6;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include query-to(1440) {
              left: 0;
            }
            @include query-to(760) {
              img {
                max-width: 20px;
              }
            }
            &:hover {
              opacity: 1;
            }
            &.slick-disabled {
              visibility: hidden;
            }
          }
          .right {
            position: absolute;
            top: 50%;
            right: -70px;
            transform: translate(0,-50%);
            z-index: 1;
            cursor: pointer;
            transition: opacity .3s ease;
            opacity: .6;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include query-to(1440) {
              right: 0;
            }
            @include query-to(760) {
              img {
                max-width: 20px;
              }
            }
            &:hover {
              opacity: 1;
            }
            &.slick-disabled {
              visibility: hidden;
            }
          }
        }
      }
      .inner {
        max-width: 1020px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        @include query-to(1440) {
          padding: 0 rem(20);
        }
        @include query-to(760) {
          flex-direction: column;
        }
        .left {
          max-width: 210px;
          width: 100%;
          flex: 1 0 auto;
          text-align: right;
          padding-right: rem(15);
          border-right: solid 1px $white;
          @include query-to(760) {
            border: none;
            flex: auto;
            padding-bottom: rem(20);
            max-width: 100%;
            text-align: left;
          }
          span {
            display: inline-block;
            font-weight: 800;
            line-height: 1.25;
            padding-bottom: 2rem;
            text-transform: uppercase;
            @include query-to(760) {
              font-size: rem(18);
            }
          }
          p {
            font-weight: 700;
            line-height: 1.25;
            @include query-to(760) {
              font-size: rem(14);
              font-weight: 600;
            }
          }
        }
        .right {
          width: 100%;
          padding: 0 0 0 rem(15);
          font-weight: 300;
          @include query-to(760) {
            padding: 0;
          }
          p {
            line-height: 1.25;
            padding-bottom: rem(20);
            &:last-child {
              padding-bottom: 0;
            }
            @include query-to(760) {
              font-size: rem(14);
            }
          }
        }
      }
      &#Rides {
        .project {
          cursor: default;
        }
      }
    }

    /* Fade in tabs */
    @-webkit-keyframes fadeEffect {
      from {opacity: 0;}
      to {opacity: 1;}
    }

    @keyframes fadeEffect {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }
}

// FOOTER
.footer {
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    width: 1200px;
    height: 1px;
    background-color: #606060;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  /* Style the tab */
  position: relative;
  .wrapper {
    .tabs {
      z-index: 1;
      overflow: hidden;
      position: absolute;
      top: 55px;
      left: 50%;
      transform: translate(-50%,0);
      border: solid 1px $white;
      padding: rem(9);
      @include query-to(760) {
        right: 20px;
        left: initial;
        transform: none;
        top: 160px;
        padding: rem(3);
      }

      /* Style the buttons inside the tab */
      .tab__link_3 {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0 rem(7);
        transition: 0.3s;
        font-size: rem(16);
        text-transform: uppercase;
        color: $white;
        @include query-to(760) {
          padding: 0 rem(4);
        }
        &:hover {
          //opacity: 1;
        }
        &.active {
          background-color: $white;
          color: $black;
          font-weight: 800;
        }
        p {
          @include query-to(760) {
            display: none;
          }
        }
        span {
          display: none;
          @include query-to(760) {
            display: inline-block;
          }
        }
      }

    }

    /* Style the tab content */
    .tab__content_3 {
      display: none;
      //padding: 6px 12px;
      -webkit-animation: fadeEffect 1s;
      animation: fadeEffect 1s;
      .inner {
        position: relative;
        .inner__content {
          display: none;
          position: absolute;
          right: 0;
          bottom: 0;
          @include query-to(760) {
            display: block;
          }
          .mobile-langs {}
        }
        .top {
          padding-top: rem(55);
          padding-bottom: rem(55);
          display: flex;
          justify-content: space-between;
          @include query-to(760) {
            flex-direction: column;
            justify-content: flex-start;
            padding-top: rem(50);
            padding-bottom: rem(50);
            * {
              font-size: rem(14);
              line-height: 1.3;
            }
          }
          .left {
            font-weight: 600;
            line-height: 1.25;
          }
          .right {
            font-weight: 600;
            line-height: 1.25;
            display: flex;
            flex-direction: column;
            text-align: right;
            @include query-to(760) {
              text-align: left;
              margin-top: rem(15);
              font-weight: 300;
              max-width: 180px;
              width: 100%;
            }
          }
        }
        .map-czech,
        .map-swiss {
          width: 100%;
          height: 400px;
          @include query-to(760) {
            height: 280px;
          }
        }
      }
    }

    /* Fade in tabs */
    @-webkit-keyframes fadeEffect {
      from {opacity: 0;}
      to {opacity: 1;}
    }

    @keyframes fadeEffect {
      from {opacity: 0;}
      to {opacity: 1;}
    }
  }
  .copyright {
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: rem(55) rem(20) rem(27);
      @include query-to(760) {
        padding: rem(25) rem(20);
        grid-template-columns: 1fr 1fr;
        @include query-to(760) {
          * {
            font-size: rem(14);
            line-height: 1.3;
          }
        }
      }
      .left {
        p {
          font-weight: 600;
          line-height: 1.25;
          &:last-child {
            @include query-to(760) {
              font-weight: 300;
            }
          }
        }
      }
      .center {
        text-align: center;
         @include query-to(760) {
          display: none;
          img {
            height: 30px;
          }
         }
      }
      .right {
        text-align: right;
        font-weight: 600;
        line-height: 1.25;
        p {
          @include query-to(760) {
            display: none;
          }
        }
        ul.social {
          list-style: none;
          display: block;
          padding-top: rem(3);
          li {
            display: inline-block;
            margin-right: rem(5);
            &:last-child {
              margin-right: 0;
            }
            a {
              transition: opacity .3s ease;
              &:hover {
                opacity: .8;
              }
            }
          }
        }
      }
    }
  } 
}

body.touch {
  .projects-slider-navigation {
    display: none;
  }
}