.header {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   z-index: 100;
   width: 100%;
   height: auto;
   margin: 0 auto;
   padding: rem(20) 0;
   transition: all 0.35s ease;
   background-color: $black;
   transition: all .3s ease-in-out,top .8s ease-in-out;

   .brand {
      z-index: 1;
      overflow: hidden;
      transition: all .3s ease;
      width: auto;

      @include query-to(760) {
        width: 40px;
      }
      
      img {
         width: initial;
         max-width: initial;
         transition: all .3s ease;
         @include query-to(760) {
            width: 132px !important;
            height: 29px;
         }
      }
   }

   .lang {
      display: inline-flex;
      align-items: center;
      border: solid 1px $white;
      margin-top: rem(5);
      margin-bottom: rem(20);
      cursor: pointer;
      ul {
         display: flex;
         list-style: none;
         width: 35px;
         overflow: hidden;
         transition: all .3s ease;

         li.lang-item {
            display: inline-block;
            padding: rem(5) rem(6) rem(2) rem(6);
            line-height: 1.25;
            &.current-lang {
               display: none;
               background-color: $white;
               a {
                  color: $black;
               }
            }
         }
      }
      .arrow {
         display: inline-block;
         width: 11px;
         height: 17px;
         margin: 0 rem(5);
         background: url("../img/icons/langs-arrow.svg") center no-repeat;
         transition: rotate .3s ease;
      }
      &--opened {
         ul {
            transition: all .3s ease;
            width: 106px;
            li.active {
               background-color: $white;
               color: $black;
            }
         }
         .arrow {
            transform: rotate(180deg);
         }
      }
      @include query-to(760) {
         display: none;
         position: absolute;
         left: 20px;
         bottom: 5px;
         ul {
            list-style: none;
         }
      }
   }

   .social {
      list-style: none;
      display: none;
      padding-top: rem(3);
      position: absolute;
      right: 20px;
      bottom: 25px;
      li {
         display: inline-block;
         margin-right: rem(5);
         &:last-child {
            margin-right: 0;
         }
         a {
            transition: opacity .3s ease;
            img {
               @include query-to(760) {
                  width: 27px;
               }
            }
            &:hover {
               opacity: .8;
            }
         }
      }
   }

   @include query-to(760) {
      padding: rem(25) rem(20);
   }

   &.on-scroll {
      background: $black;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);;
   }
   &.nav-up {
      top: -112.5px;
   }
   &.header--open {
      .brand {
         width: auto;
         transition: all .3s ease;
         img {
            transition: all .3s ease;
            width: 100%;
            max-width: 100%;
            height: 29px;
         }
      }
      .social {
         display: block;
      }
      .lang {
         display: inline-flex;
      }
   }
}

.navbar {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   column-gap: 1rem;
   width: 100%;
   margin: 0 auto;
   @include query-to(760) {
      padding: 0 !important;
   }
}

.menu {
   position: fixed;
   top: -100%;
   left: 0;
   width: 100%;
   height: auto;
   padding: 4rem 0 3rem;
   overflow: hidden;
   background-color: $black;
   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);;

   &.is-active {
      top: 78.45px;
      width: 100%;
      height: calc(100% - 78.45px);
      padding: 30px 20px 80px;
      &:before {
         content: "";
         display: block;
         width: calc(100% - 40px);
         height: 1px;
         top: 0;
         left: 0;
         right: 0;
         position: absolute;
         margin: 0 auto;
         background-color: #606060;

      }
   }

   &-inner {
      
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 1.25rem;
   }

   &-link {
      font-family: inherit;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: $white;
      text-transform: uppercase;
      transition: opacity .3s ease;
      &:hover {
         opacity: .6;
      }
      @include query-to(760) {
        font-weight: 600;
      }
   }

   @media only screen and (min-width: 759px) {
      position: relative;
      top: 0;
      width: auto;
      height: auto;
      padding: 0rem;
      margin-right: auto;
      background: none;
      box-shadow: none;

      &-inner {
         display: flex;
         flex-direction: row;
         column-gap: rem(30);
         margin: 0 auto;
         list-style: none;
      }
   }
}

.burger {
   position: relative;
   display: block;
   cursor: pointer;
   user-select: none;
   order: -1;
   z-index: 10;
   width: 1.75rem;
   height: 1rem;
   border: none;
   outline: none;
   background: none;
   visibility: visible;
   transform: rotate(0deg);
   transition: 0.35s ease;

   @media only screen and (min-width: 759px) {
      display: none;
      visibility: hidden;
   }

   &-line {
      position: absolute;
      display: block;
      right: 0;
      width: 100%;
      height: 2px;
      border: none;
      outline: none;
      opacity: 1;
      transform: rotate(0deg);
      background-color: $white;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
         top: 0px;
      }
      &:nth-child(2) {
         top: 0.5rem;
      }
      &:nth-child(3) {
         top: 1rem;
      }
   }

   &.is-active .burger-line {
      &:nth-child(1) {
         top: 0.5rem;
         transform: rotate(135deg);
      }
      &:nth-child(2) {
         right: -1.5rem;
         opacity: 0;
         visibility: hidden;
      }
      &:nth-child(3) {
         top: 0.5rem;
         transform: rotate(-135deg);
      }
   }
}