/*==========================================================================*\
  # Functions
\*==========================================================================*/
@mixin box-sizing($type) {
  box-sizing: $type;
}

@mixin clearfix() {
  *zoom: 1;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@function rem($size) {
  @return calc($size / 10) * 1rem;
}

$q-unit: 1px;
@mixin query-to($media) {
  @media (max-width: $media * $q-unit) {
    @content;
  }
}
