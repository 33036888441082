/*==========================================================================*\
  # Container
\*==========================================================================*/

.container {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding: 0 rem(20);
  &--medium {
    max-width: 1240px;
  }
  &--small {
    max-width: 1060px;
  }
  &--no-padding {
    padding: 0;
  }
}