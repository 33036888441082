.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: $black;
  z-index: 100;
  opacity: 0;
  transition: 0.3s;
  overflow: auto;

  .container {
    transition: 0.3s;
    padding: rem(100) 0;
    @include query-to(760) {
      padding: rem(70) 0;
    }

    .modal-slider {
      position: relative;

      .project-slider-detail__content {
        position: relative;
        outline: none;

        .overlay {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-weight: 800;
            font-size: rem(60);
            line-height: 1.13;
            text-transform: uppercase;
            @include query-to(1240) {
              font-size: rem(50);
            }
            @include query-to(1140) {
              font-size: rem(40);
            }
            @include query-to(900) {
              font-size: rem(30);
            }
            @include query-to(620) {
              display: none;
            }

            &.black {
              color: $black;
            }
          }
        }
      }

      .project-slider-detail-navigation {

        @include query-to(760) {
          display: none;
        }

        .left {
          position: absolute;
          top: 50%;
          left: 50px;
          transform: translate(0,-50%);
          z-index: 1;
          cursor: pointer;
          transition: opacity .3s ease;
          opacity: .6;

          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include query-to(1440) {
            left: 0;
          }
          @include query-to(760) {
            img {
              max-width: 20px;
            }
          }

          &:hover {
            opacity: 1;
          }

          &.slick-disabled {
            visibility: hidden;
          }
        }

        .right {
          position: absolute;
          top: 50%;
          right: 50px;
          transform: translate(0,-50%);
          z-index: 1;
          cursor: pointer;
          transition: opacity .3s ease;
          opacity: .6;

          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include query-to(1440) {
            right: 0;
          }
          @include query-to(760) {
            img {
              max-width: 20px;
            }
          }

          &:hover {
            opacity: 1;
          }

          &.slick-disabled {
            visibility: hidden;
          }
        }
      }

      .slick-dotted .slick-slider {
        margin-bottom: 30px;
      }

      .slick-dots {
        position: absolute;
        bottom: 25px;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        z-index: 1;
        @include query-to(600) {
          bottom: 5px;
        }

        li {
          position: relative;
          display: inline-block;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;
          height: 3px;
          width: 50px;
          @include query-to(600) {
            width: 25px;
          }

          button {
            position: relative;
            background-color: #fff;
            opacity: .25;
            width: 50px;
            height: 3px;
            padding: 0;
            border: 0;
            display: block;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            cursor: pointer;
            transition: all .3s ease;
            @include query-to(600) {
              width: 25px;
            }

            &:hover, &:focus {
              outline: none;
            }
          }

          &.slick-active button {
            color: #fff;
            opacity: .75;
          }
        }
      }
    }
    .content {
      display: flex;
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      padding-left: rem(20);
      padding-right: rem(20);
      padding-top: rem(70);
      @include query-to(760) {
        flex-direction: column;
        padding-top: rem(50);
      }

      .left {
        max-width: 255px;
        width: 100%;
        flex: 1 0 auto;
        text-align: right;
        padding-right: rem(15);
        border-right: solid 1px $white;
        @include query-to(760) {
          max-width: 100%;
          flex: auto;
          padding-right: 0;
          border-right: none;
          text-align: left;
          padding-bottom: rem(20);
        }

        p {
          font-weight: 800;
          line-height: 1.25;
          padding-bottom: rem(20);
          @include query-to(760) {
            font-size: rem(18);
          }
        }

        & > :last-child {
          padding-bottom: 0;
          @include query-to(760) {
            font-size: rem(14);
            font-weight: 600;
          }
        }
      }

      .right {
        width: 100%;
        padding: 0 0 0 rem(15);
        font-weight: 300;

        @include query-to(760) {
          padding: 0;
        }

        p {
          line-height: 1.25;
          padding-bottom: rem(20);
        }

        & > :last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  
  &.active {
    opacity: 1;
    pointer-events: auto;
    .modal-slider {
      .project-slider-detail {
        /*.project-slider-detail__content {
          .overlay {
            opacity: 0;
          }
        }*/
        .project-slider-detail__content.slick-current[data-slick-index="0"] {
          .overlay {
            opacity: 0;
            animation: signup-response 3s 1;
            animation-fill-mode: forwards;
            animation-delay: .4s;
          }
          @keyframes signup-response {
            0% {
              opacity :0;
              transform: translate3d(0,100px,0);
            }
            25% {
              opacity :1;
              transform: translate3d(0,0,0);
            }
            50% {
              opacity :1;
              transform: translate3d(0,0,0);
            }
            75% {
              opacity :1;
              transform: translate3d(0,0,0);
            }
            100% {
              opacity :0;
            }
          }
        }
      }
    }
  }
  
  .close {
    position: fixed;
    top: 50px;
    right: 50px;
    cursor: pointer;
    width: 27px;
    height: 27px;
    background: url("../img/icons/modal-close-icon.svg") center no-repeat;
    background-size: contain;
    z-index: 1;
    @include query-to(760) {
      top: 20px;
      right: 20px;
    }
  }
}

body.touch {
  .modal {
    .project-slider-detail-navigation {
      display: none;
    }
  }
}

// Cookies modal
.modal-wrapper {
  z-index: -99;
  width: 100%;
  height: 100%;
  position: fixed;
  top :0;
  left: 0;
  background: rgba(29,29,27,.95);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  padding: 10vh rem(20);
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 890px) and (max-width: 660px) {
    padding: 0;
  }

  .modal-2 {
    max-width: 900px;
    width: 100%;
    display: block;
    margin: 0 auto;
    opacity :0;
    transition: opacity 0.5s ease-in-out;
    background-color: #1D1D1B;
    overflow-x: hidden;
    border: solid 2px #686868;

    @media (max-height: 890px) and (max-width: 660px) {
      border-radius: 0;
    }

    .modal__content {
      position: relative;
      padding: rem(70) rem(40) rem(20);
      text-transform: uppercase;
      text-align: center;

      @include query-to(900) {
        padding: rem(50) rem(40) rem(20);
      }

      h2 {
        font-size: rem(85);
        line-height: 1;

        @include query-to(900) {
          font-size: rem(60);
          margin-bottom: rem(10);
        }
        @include query-to(600) {
          font-size: rem(45);
        }
      }
      .position {
        max-width: 780px;
        padding: 0 rem(120);
        line-height: 1;
        margin: 0 auto rem(10);
        font-size: 35px;
        font-weight: 800;
        margin-bottom: rem(10);
        position: relative;

        @include query-to(900) {
          font-size: 20px;
          max-width: 410px;
          padding: 0 rem(50);
          margin-bottom: rem(15);
        }
        @include query-to(540) {
          max-width: 300px;
        }

        &:before {
          content: "––––––";
          position: absolute;
          left: 0;
          top: 0;
          font-weight: 200;

          @include query-to(900) {
            content: "–––";
          }
          // @include query-to(600) {
          //   display: none;
          // }

        }
        &:after {
          content: "––––––";
          position: absolute;
          right: 0;
          top: 0;
          font-weight: 200;

          @include query-to(900) {
            content: "–––";
          }
          // @include query-to(600) {
          //   display: none;
          // }
        }
      }

      .note {
        font-size: 20px;
        margin-bottom: 50px;

        @include query-to(900) {
          font-size: 16px;
        }
      }

      .external-link {
        border: solid 2px white;
        padding: 7px 47px;
        font-weight: 600;
        position: relative;
        transition: opacity .3s ease;

        &:after {
          content: url("../img/icons/external-link-icon.svg");
          position: absolute;
          right: 8px;
          top: 6px;
        }

        &:hover {
          opacity: .7;
        }
      }

    }
  }

  &.open {
    z-index: 101;
    opacity: 1;
    visibility: visible;

    .modal-2 {
      opacity: 1;
    }
  }
  .close-2 {
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: opacity .3s ease;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      width: 20px;
      height: 2px;
      display: block;
      background: $white;
    }

    &:before {
      margin-top: 12px;
      transform:rotate(45deg);
    }

    &:after {
      margin-top: -2px;
      transform:rotate(-45deg);
    }

    &:hover {
      opacity: .7;
    }

  }
}
