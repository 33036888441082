body {
  font-family: $font-montserrat;
  font-size: $base-size;
  font-weight: normal;
  line-height: 1.4;
  background-color: $black;
  color: $white;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

body img.gform_ajax_spinner {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-montserrat;
  font-size: inherit;
  font-weight: bold;
  line-height: inherit;
  color: $white;

  a {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

main {}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
figure {
  margin-bottom: 0;
}

ul ul,
ol ol,
ul ol,
ol ul {
  margin-bottom: 0;
}

blockquote {
  font-style: italic;

  p {
    margin: 0;
  }
}

a {
  color: $white;
  text-decoration: none;
}

.hidden,
.slick-hidden {
  display: none !important;
}


// all devices but not iOS
@supports not (-webkit-touch-callout: none) {
  .slick-slider {
    touch-action: auto !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}
