:root {
	--preload-color-background: #000000;
	--preload-color-foreground: #ffffff;
	--preload-color-accent: #03a9f4;
	--preload-gap: 36px;
	--preload-icon-size: 300px;
	--preload-spinner-size: 32px;
	--preload-spinner-width: 3px;
}

#preloader {
    z-index: 99999999999;
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	gap: var(--preload-gap);
	justify-content: center;
	align-items: center;
	background: var(--preload-color-background);
	color: var(--preload-color-foreground);
	font: var(--preload-font);
	transition: .3s ease-in-out;
}

#preloader .preloader__logo {
	width: var(--preload-icon-size);
	height: var(--preload-icon-size);
	color: var(--preload-color-accent);
	filter: opacity(0);
	animation: preloader-logo-anim .3s .5s ease-out forwards;
	@include query-to(600) {
		width: 200px;
	}
}

#preloader .preloader__title:before {
	content: var(--preload-title);
	filter: opacity(0);
	animation: preloader-title-anim .3s .6s ease-out forwards;
}

#preloader .preloader__spinner {
	position: fixed;
	bottom: var(--preload-gap);
	left: calc(50vw - calc(var(--preload-spinner-size) / 2px));
	width: var(--preload-spinner-size);
	height: var(--preload-spinner-size);
	animation: preloader-spinner .5s linear infinite;
}

#preloader .preloader__spinner .preloader__spinner__icon {
	width: var(--preload-spinner-size);
	height: var(--preload-spinner-size);
	stroke: var(--preload-color-foreground);
	stroke-width: var(--preload-spinner-width);
	stroke-dasharray: 314%, 314%;
	animation: preloader-spinner-icon-anim 1s linear infinite alternate;
}

@keyframes preloader-logo-anim {
	0% {
		filter: opacity(0);
		transform: translateY(-30px);
	}

	100% {
		filter: opacity(1);
		transform: translateY(0);
	}
}

@keyframes preloader-title-anim {
	0% {
		filter: opacity(0);
	}

	100% {
		filter: opacity(1);
	}
}

@keyframes preloader-spinner {
	0% {
		transform: rotate(0);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes preloader-spinner-icon-anim {
	0% {
		stroke-dasharray: 0%, 314%;
	}

	100% {
		stroke-dasharray: 314%, 314%;
	}
}